<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="1000"
                        :items="settings"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        
                                    </div>
                                    <div class="col-md-4">
                                        
                                    </div>
                                    
                                    
                                </div>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/settings/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Cài đặt hệ thống'
    },
    data() {
        return {
            loading: false,
            options: {},
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Key Name", sortable: false, value: 'Key_name'},
                { text: "Giá trị", sortable: false, value: 'Value_config'},
                { text: "Actions", value: 'actions', sortable: false },
            ]
        };
    },
    watch: {
        
    },
    computed: {
        settings() {
            return this.$store.getters['settings/get_settings'];
        },
    },
    components: {
        
    },
    methods: {
        get_settings() {
            var self    = this;
            self.loading = true;

            this.$store.dispatch('settings/get_all').then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        edit_item(item) {
            this.$router.push(`settings/update/${item.id}`).catch(() => { });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Cài đặt", route: "index" } ]);
        this.get_settings();
    }
};
</script>
